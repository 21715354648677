import { mapData } from "@/stuff/DataMapping";
import { MatrixModule, IMatrixModule } from "./MatrixModule";

export interface IMatrixSupplier {
    id: number; 
    name: string;
    postcode: string;
    tradeIDs: Array<number>;
    modules: Array<IMatrixModule>;
}

export class MatrixSupplier implements IMatrixSupplier {

    constructor(data?: IMatrixSupplier) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IMatrixSupplier) {
        mapData(data, { 
            root: () => this,
            modules: () => new MatrixModule(),
        });
    }

    id: number = 0; 
    name: string = "";
    postcode: string = "";
    tradeIDs: Array<number> = [];
    modules: Array<MatrixModule> = [];
}