
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import { Watch } from "vue-property-decorator";
    import ApiButton from "@/components/ApiButton.vue";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { User } from "@/model/User";
    import { IMatrixSupplier, MatrixSupplier } from "@/model/MatrixSupplier";
    import { IMatrixModule } from "@/model/MatrixModule";
    import { SupplierSearchParameters } from "@/model/SupplierSearchParameters";
    import { IRegionLookupItem } from '@/model/RegionLookupItem';
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { ResponseSetStatus, TriState } from "@/model/Enums";
    import store from "@/store/store";
    import { ISite, Site } from "@/model/Site";
import { TradeSearchParameters } from "@/model/TradeSearchParameters";
import { ITrade } from "@/model/Trade";
  
    @Component({
        components: { ApiButton }
    })
    export default class Histories extends Vue {

        constructor() {
            super();
            // setting parameters here to avoid tripping watchers...
            this.searchParameters = new SupplierSearchParameters();
            this.searchParameters.isDormant = TriState.False;
        }

        created(): void {
            window.addEventListener("resize", this.onWindowResize);
        }

        destroyed(): void {
            window.removeEventListener("resize", this.onWindowResize);
        }

        async mounted(): Promise<void> {    
            const user = this.$store.state.signedInUser as User;
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            const buyerID = this.isBuyerZone
                ? user == null ? 0 : +user.buyerID
                : 0;

            this.searchParameters.buyerID = buyerID;

            // fire off the loads in parallel...
            // (infinite loader will fire off the main search)
            await Promise.all([
                this.loadTradeList(),
                this.loadBuyerList(),
                this.loadRegionList(),
                this.loadSiteList()
            ]);

            if (store.state.matrixParameters != null) {      
                const json = JSON.stringify(store.state.matrixParameters)
                this.searchParameters = new SupplierSearchParameters(JSON.parse(json)) ;
                if (this.isBuyerZone) {
                    this.searchParameters.buyerID = buyerID;
                    this.searchParameters.isBuyerZone = true;
                }

                window.setTimeout(() => {
                    const params = store.state.matrixParameters;  
                    this.searchParameters.regionID = params?.regionID ?? 0;
                }, 50);
                window.setTimeout(() => {
                    const params = store.state.matrixParameters;  
                    this.searchParameters.siteID = params?.siteID ?? 0;

                    this.initDone = true;
                    this.onWindowResize();
                    this.refreshSearch();
                }, 100);
            }
            else {
                this.initDone = true;
                this.onWindowResize();
            }

        }

        //
        // -- properties
        //

        infiniteId = +new Date();
        initDone = false;

        searchIsBusy: boolean = false;
        searchParameters: SupplierSearchParameters;
        totalCount: number = -1;
        suppliers: Array<IMatrixSupplier> = [];
        isBuyerZone: boolean = false;

        buyerList: Array<ILookupItem> = [];
        buyerOptions: Array<ILookupItem> = [];

        tradeList: Array<ILookupItem> = [];
        get tradeOptions(): Array<ILookupItem> { 
            return this.tradeList.length === 0 && !utils.isEmptyId(this.searchParameters.buyerID) 
                ? [ new LookupItem( { id: 0, description:  "(No trades for this buyer!)", isArchived: false }) ]
                : utils.selectOptions(this.tradeList, "Any Trade"); }

        regionList: Array<IRegionLookupItem> = [];
        get regionOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu => lu.id > 0 && !lu.isArchived && lu.buyerID === this.searchParameters.buyerID);
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "(No regions for this buyer!)", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Any region", isArchived: false }), ...filtered];
        }

        siteList: Array<Site> = [];
        get siteOptions(): Array<LookupItem> {
            const filtered = this.siteList
                .filter(s => s.id > 0 && !s.isDeleted && s.regionID == this.searchParameters.regionID)
                .map(s => new LookupItem( { id: s.id, description: s.description, isArchived: false } ));
            return filtered.length === 0
                ? [ new LookupItem( { id: 0, description:  "(No locations for this region!)", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Any Location", isArchived: false }), ...filtered];
        }

        get isDormantSearchOptions(): Array<ILookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "Any Status", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Dormant", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Active", isArchived: false })
        ];}

        get roleOptions(): Array<ILookupItem> { return [
            new LookupItem({ id: 0, description: "Any Role", isArchived: false }),
            new LookupItem({ id: 1, description: "Consultant", isArchived: false }),
            new LookupItem({ id: 2, description: "Contractor", isArchived: false }),
            new LookupItem({ id: 3, description: "Consultant & Contractor", isArchived: false })
        ];}

        get complianceOptions(): Array<LookupItem> { return [
            new LookupItem({ id: 0, description: "Non Compliant Only", isArchived: false }),
            new LookupItem({ id: -1, description: "All", isArchived: false }),
            new LookupItem({ id: 1, description: "Compliant Only", isArchived: false }),
        ];}

        get alphabet(): Array<string> {
            const alpha = Array.from(Array(26)).map((e, i) => i + 65);
            const alphabet = [ ];
            alphabet.push(...alpha.map((x) => String.fromCharCode(x)));
            alphabet.push(...[...Array(10).keys()].map(n => n.toString()));
            return alphabet;
        }

        get selectedRole(): number {
            if (this.searchParameters.isConsultant && this.searchParameters.isContractor) return 3;
            else if (this.searchParameters.isConsultant) return 1;
            else if (this.searchParameters.isContractor) return 2;
            else return 0;
        }
        set selectedRole(value: number) {
            this.searchParameters.isConsultant = value == 1 || value == 3;
            this.searchParameters.isContractor = value == 2 || value == 3;
            this.refreshSearch();
        }
        
        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        tradesHtml(supplier: IMatrixSupplier): string {
            if(supplier.tradeIDs.length === 0) return "(no trades)"
            if(this.tradeList.length === 0) return "";
            const trades = supplier.tradeIDs.map(id => utils.lookupDescription(id, this.tradeList)).join(", ");
            return trades;
        }

        moduleHasExpired(module: IMatrixModule): boolean {
            return utils.hasDateValue(module.expires) && module.expires < utils.today();
        }

        moduleIconGlyphCss(module: IMatrixModule): string {
            if(this.moduleHasExpired(module)) return "fas fa-times";
            if(module.latestStatus === ResponseSetStatus.None) return "fas fa-times";
            if(module.latestStatus === ResponseSetStatus.Legacy) return "fas fa-check";
            if(module.latestStatus === ResponseSetStatus.Accepted) return "fas fa-check";
            return "fas fa-tasks"; // default = in progress (incl legacy)
        }

        moduleIconVariant(module: IMatrixModule): string {
            if(this.moduleHasExpired(module)) return "danger";
            if(module.latestStatus === ResponseSetStatus.None) return "danger";
            if(module.latestStatus === ResponseSetStatus.Legacy) return "success";
            if(module.latestStatus === ResponseSetStatus.Accepted) return "success";
            return "warning"; // default = in progress (incl legacy)
        }

        moduleStatusDescription(module: IMatrixModule): string {
            if (this.moduleHasExpired(module)) return "Expired";
            if (module.latestStatus === ResponseSetStatus.None && utils.hasDateValue(module.expires)) return "Expired";
            if (module.latestStatus === ResponseSetStatus.None) return "None";
            if (module.latestStatus === ResponseSetStatus.Legacy) return "Current legacy";
            if (module.latestStatus === ResponseSetStatus.Accepted) return "Current and approved";
            if (module.latestStatus === ResponseSetStatus.LegacyInProgress) return "In progress (legacy)";
            if (module.latestStatus === ResponseSetStatus.Rejected) return "In progress - Rejected";
            if (module.latestStatus === ResponseSetStatus.AwaitingCompletion) return "In progress - Awaiting Completion";
            if (module.latestStatus === ResponseSetStatus.Submitted) return "In progress - Submitted";
            return "In progress";
        }

        moduleBoxCss(module: IMatrixModule): string {
            return `alert-${this.moduleIconVariant(module)}`;
        }   

        moduleExpiresText(module: IMatrixModule): string {
            return utils.hasDateValue(module.expires) ? utils.dateText(module.expires) : "- - -"
        }

        //
        // -- watchers and events
        //

        debouncedSearch = utils.debounce(this.refreshSearch, 1000)

        @Watch("searchParameters.searchText")
        onSearchTextChanged(): void {
            this.debouncedSearch();
        }

        @Watch("searchParameters.buyerID")
        async onBuyerIdChanged(): Promise<void> {
            await this.loadTradeList();

            if (this.searchParameters.regionID === 0) this.refreshSearch();
            else this.searchParameters.regionID = 0;

            if (utils.isEmptyId(this.searchParameters.buyerID)) {
                this.searchParameters.showBuyerDormantSuppliers = false;
            }
        }

        @Watch("searchParameters.regionID")
        onRegionIdChanged(): void {
            if (this.searchParameters.siteID === 0) this.refreshSearch();
            else this.searchParameters.siteID = 0;
        }

        @Watch("searchParameters.siteID")
        onSiteIdChanged(): void {
            this.refreshSearch();
        }

        @Watch("searchParameters.isDormant")
        onIsDormantChanged(): void {
            this.refreshSearch();
        }

        @Watch("searchParameters.amFollowing")
        onFollowingChanged(): void {
            this.refreshSearch();
        }

        @Watch("searchParameters.tradeID")
        onTradeChanged(): void {
            this.refreshSearch();
        }

        @Watch("searchParameters.fullyComplaintID")
        onCompliantChanged(): void {
            this.refreshSearch();
        }

        @Watch("searchParameters.supplierChar") 
        onSupplierCharChanged(): void {
            this.refreshSearch();
        }

        @Watch("searchParameters.showBuyerDormantSuppliers")
        onShowBuyerDormantSuppliersChanged(): void {
            this.refreshSearch();
        }

        // We want the container to be fixed to window size so that bottom (horiz) scroll bar shows
        // (possibly not a very Vue-ey way to do this...)
        onWindowResize(): void {
            const size = utils.getWindowSize();
            const container: HTMLDivElement = document.getElementsByClassName("supplierListContainer")[0] as HTMLDivElement;
            const height = Math.max((size?.height ?? 800) - (this.isBuyerZone ? 335 : 305), 300);
            container.style.height = `${height}px`;
        }

        //
        // -- methods
        //

        goToSupplier(supplier: IMatrixSupplier): void {
            const route = { 
                path: `${this.isBuyerZone ? '/buyerZone' : ''}/Supplier/${supplier.id}`,
                query: { goToTab: "modules" } 
            };
            this.$router.push(route);
        }

        async downloadExcel(event?: Event): Promise<void> {
            const blob: Blob = await apiClient.post("api/supplier/SupplierMatrixExcel", this.searchParameters, event);
            utils.downloadBlob(document, blob, "SupplierModules.xlsx");
        }

        async downloadPdf(event?: Event): Promise<void> {
            const blob: Blob = await apiClient.post("api/report/supplierModuleMatrix", this.searchParameters, event);
            utils.downloadBlob(document, blob, `Status Overview.pdf`);
        }

        async loadTradeList(): Promise<void> {
            const params = new TradeSearchParameters();
            params.buyerID = this.searchParameters.buyerID;
            const response = await apiClient.post("api/trade/search", params);
            const trades: Array<ITrade> = response.list;
            this.tradeList = trades.map<LookupItem>(t => new LookupItem({
                id: t.id,
                description: t.description,
                isArchived: utils.hasDateValue(t.deleted)
            }));

            if (this.tradeOptions.findIndex(t => t.id == this.searchParameters.tradeID) < 0) {
                this.searchParameters.tradeID = 0;
            }
        }

        async loadBuyerList(): Promise<void> {
            this.buyerList = await apiClient.get("/api/buyer/lookups?supplierID=0");
            this.buyerOptions = utils.selectOptions(this.buyerList, "Any buyer");
        }

        async loadRegionList(): Promise<void> {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        async loadSiteList(): Promise<void> {
            this.siteList = []
            const data: Array<ISite> = await apiClient.get("api/site/lookups");
            this.siteList.push(...data.map(s => new Site(s)));
        }

        resetSearch():void {
            utils.resetObject(this.searchParameters);

            this.initDone = false;
            this.searchParameters.isDormant = TriState.False;
            const user = this.$store.state.signedInUser as User;
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            const buyerID = this.isBuyerZone ? user == null ? 0 : +user.buyerID : 0;
            this.searchParameters.buyerID = buyerID;
            this.searchParameters.fullyComplaintID = -1;
            this.searchParameters.isBuyerZone = this.isBuyerZone;
            this.initDone = true;

            this.refreshSearch();
        }

        async refreshSearch(): Promise<void> {
            if (!this.initDone) return;
            this.searchParameters.pageNumber = 0;
            this.totalCount = -1;
            this.suppliers = [];
            this.infiniteId += 1;
            //this.search()
        }

        // async refreshSearch(event?: Event) {
        //     if (!this.initDone) return;
        //     this.searchParameters.pageNumber = 1;
        //     this.totalCount = -1;
        //     this.suppliers = [];
        //     await this.search(event);
        //     this.infiniteId += 1;
        // }

        async infiniteLoadingHandler(stateChanger: StateChanger): Promise<void> {
            if (!this.initDone) return;
            if (this.suppliers.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.suppliers.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        async search(event?: Event): Promise<void> {
            if (!this.initDone) return;
            if (this.searchIsBusy) {
                console.log("Skipped search because busy...");
                return;
            }
            this.searchIsBusy = true;

            // couple of checks just in case
            const user = this.$store.state.signedInUser as User;
            if(!user.isCqmsOrSuperAdmin && !user.isBuyer) return;
            if(user.isBuyer && this.searchParameters.buyerID === 0) return;

            const response = await apiClient.post("/Api/Supplier/ModuleMatrix", this.searchParameters, event);
            this.totalCount = response.count;
            this.searchParameters.isBuyerZone = this.isBuyerZone;
            this.suppliers.push(...response.list.map((s: IMatrixSupplier) => new MatrixSupplier(s)));
            this.searchIsBusy = false;
            await store.dispatch("setMatrixParameters", this.searchParameters);
        }
    }
